import '../App.css';
import styled from 'styled-components';
import Footer from "../components/Foot";
import React from 'react';
import Navbar from "../components/Navbar";
import Consistencia from "../components/Consistencia";
import Estrategia from "../components/Estrategia";
import {Link} from "react-router-dom";


function Thanks() {

    const [flexD, setFlex] = React.useState("row");
    const [alignT, setText] = React.useState("left");
    const [marginI, setMargin] = React.useState("5vw");
    const [widthT, setWidth] = React.useState("40vw");

window.addEventListener("resize", () => {

    if(window.innerWidth <= 1017){
        setFlex("column")
        setText("center")
        setMargin("0vw")
        setWidth("80vw")
    }else{
        setFlex("row")
        setText("left")
        setMargin("5vw")
        setWidth("40vw")
    }

    console.log(window.innerWidth)

  })

const windowWidth = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: flexD,
    height: "100vh",
    backgroundColor: "#284177",
    textAlign: alignT,

}


    return(
        <div style={windowWidth}>
                <img src="/logo-removebg-preview.png" style={{width: "10%", marginBottom: "2vh"}}></img>
                <div style={{alignItems: "center" , justifyContent: "center"  , flexDirection: "column" , marginLeft: marginI}}>
                    <h1 style={{color: "white" , width: widthT}}>Sucesso! Sua mensagem foi enviada, agradecemos o contato!</h1>
                    <h2 style={{color: "white"}}>Em breve retornaremos.</h2>
                    <Link to={`/`} >
                        <button style={{color:"white", background: "transparent" , fontSize: "20px" , borderColor: "white" , padding: "10px" , cursor: "pointer" , boxShadow: "5px 5px 5px rgba(0, 0, 0, 0.2)" , marginTop: "2vh"}}>Clique aqui para retornar para o site</button>
                    </Link>
                </div>
        </div>

    )
}

export default Thanks