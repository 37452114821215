import '../App.css';
import styled from 'styled-components';
import React from 'react';
import {Link} from "react-router-dom";


const Divconsistencia = styled.div`
  display: inline-flex;
  flex-direction: row;
  @media screen and (max-width: 1084px) {
    display: flex;
    flex-direction: column;

  }

`

const Consistenci = styled.div`
  margin-top: 11vh;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 100vw;


`

const Textopequeno = styled.p`
  text-align: left;
  font-size: 30px;
  z-index: 50;
  width: 45vw;
  position: relative;
  @media screen and (max-width: 1084px) {
    width: 70vw;
    font-size: 22px;
  }

`

const Titulo = styled.h1`
  text-align: left;
  margin-left: 15vw;
  font-weight: 400;
  font-size: 60px;
  z-index: 50;
  position: relative;
  @media screen and (max-width: 1287px) {
    font-size: 50px;
  }
  @media screen and (max-width: 1084px) {
    width: 70vw;
    font-size: 40px;
  }
  @media screen and (max-width: 616px) {
    font-size: 40px;
  }


`

function Consistencia(props) {


    return(
        <Consistenci>
        <img src="/WhatsApp Image 2024-01-30 at 20.33.19 (3).jpeg" style={{height: "69vh" , filter: "brightness(50%)", zIndex: "0"}}></img>
        <div style={{marginLeft: "-5vw" , position: "absolute"}}>
          <Titulo style={{ marginLeft: "0"}}>MAISinho Teens 🤙🏻</Titulo>
          <Divconsistencia> 
            <Textopequeno >Na educação financeira comece a guardar dinheiro para seus filhos, uma conta PARÇA e digital para crianças e adolescentes </Textopequeno>
            
            {props.contato == true ?
            <Link to={`/Contato`}>
              <button type='button' className='botaoBranco'>DESCUBRA</button>
            </Link>
            :
            <Link to={`/Servicos`}>
              <button type='button' className='botaoBranco'>DESCUBRA</button>
            </Link>
            }
            
          </Divconsistencia>
        </div>
        
      </Consistenci>

    )
}

export default Consistencia