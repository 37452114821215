import '../App.css';
import styled from 'styled-components';
import {Link} from "react-router-dom";
import React from 'react';
import { useEffect } from "react";

const Textomedio = styled.h2`
  font-size: 30px;
  z-index: 50;
  position: relative;
  text-align: left;
  margin-left: 2vw;

`

const Menu = styled.div`
  float: right;
  display: inline-flex;
  margin-right: 5vw;
  width: 30vw;
  @media screen and (max-width: 1380px) {
    margin-right: 10vw;
  }
  @media screen and (max-width: 1250px) {
    margin-right: 15vw;
  }
  @media screen and (max-width: 1020px) {

  }

`

function Navbar(){
    const [isScrolled, setScrolled] = React.useState(false);
    const [HMenu, setHMenu] = React.useState(false);

    window.addEventListener("scroll", () => {
      
      if(window.scrollY  >= 500){
        setScrolled(true)
    
    
      }else{
        setScrolled(false)
  
      }    
    })

    window.addEventListener("resize", () => {

      if(window.innerWidth <= 1017){
        setHMenu(true)
      }else{
        setHMenu(false)
      }

      console.log(window.innerWidth)

    })

    useEffect(() => {

      if(window.innerWidth <= 1017){
        setHMenu(true)
      }else{
        setHMenu(false)
      }

      if(window.scrollY  >= 500){
        setScrolled(true)
    
    
      }else{
        setScrolled(false)
  
      }  



    } , [])

    return(
            <div id="navbar">
              <div className="container nav-container">
              <div style={{float: "left", width: "20vw"}}>
                <Link to={`/`}>
                <img src="/WhatsApp Image 2024-01-30 at 20.33.20 (2).jpeg" id="ImgMenu" className={`${isScrolled ? "invisible" : "visible"}`}/>
                </Link>
                
                <Link to={`/`}>
                <img src="/logo-removebg-preview.png" id="ImgMenulogo" className={`${isScrolled ? "visible" : "invisible"}`}/>
                </Link>
              </div>
              <Menu>
                <Link to={`/Sobre`} className={`${HMenu ? "invisible" : "visible"}`}>
                <Textomedio>SOBRE</Textomedio>
                </Link>
            
                <Link to={`/Servicos`} className={`${HMenu ? "invisible" : "visible"}`}>
                <Textomedio>SERVIÇOS</Textomedio>
                </Link>
                
                <Link to={`/Contato`} className={`${HMenu ? "invisible" : "visible"}`}>
                <Textomedio >CONTATO</Textomedio>
                </Link>
              </Menu>
              <input className={`${HMenu ? "checkbox" : "invisible"}`} type="checkbox" name="" id="chk" />
              <div className={`${HMenu ? "hamburger-lines" : "invisible"}`}>
                <span class="line line1"></span>
                <span class="line line2"></span>
                <span class="line line3"></span>
              </div> 
              <div className={`${HMenu ? "menu-items" : "invisible"}`}>
                <Link to={`/Sobre`} >
                <Textomedio style={{color: "#252525"}}>SOBRE</Textomedio>
                </Link>

                <hr style={{height: "0.2px"}}/>
            
                <Link to={`/Servicos`} >
                <Textomedio style={{color: "#252525"}}>SERVIÇOS</Textomedio>
                </Link>
                <hr style={{height: "0.2px"}}/>
                
                <Link to={`/Contato`}>
                <Textomedio style={{color: "#252525"}}>CONTATO</Textomedio>
                </Link>
                <hr style={{height: "0.2px"}}/>
              </div>
            </div>
        </div>
    )
}

export default Navbar