import '../App.css';
import styled from 'styled-components';
import Footer from "../components/Foot";
import React from 'react';
import Navbar from "../components/Navbar";
import { FC, useEffect, useState } from "react";


const Container = styled.div`
  display: inline-flex;
  justify-content: center;
  margin-top: 10vh;
  @media screen and (max-width: 804px) {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

  }


`
const Container2 = styled.div`
  display: flex;
  flex-direction: column;
  @media screen and (max-width: 804px) {
    width: 100%;

  }


`

const Textopequeno = styled.p`
 font-size: 20px;
 color: black;
 text-align: left;
 margin-top: -1vh;


`


const Maps = styled.div`
 width: 100%;
 height: 60vh;
 background-image: url("/Capturar.PNG");
 filter: brightness(50%);


`

const Button = styled.input`
  background-color: transparent;
  margin-width: 1px;
  float: right;
  border-width: 1px;
  border-radius: 5px;
  width: 7vw;
  height: 6vh;
  margin-top: 2vh;
  cursor: pointer;
  &:hover{
    color: white;
    background-color: #284177;
  }
  @media screen and (max-width: 1114px) {
    width: 10vw;

  }
  @media screen and (max-width: 804px) {
    width: 17vw;
    float: left;
    margin-bottom: 5vh;

  }

`


const DivInputs = styled.form`
  width: 40vw;
  margin-left: 5vw;

  @media screen and (max-width: 804px) {
    width: 80vw;
    margin-left: 0vw;
    margin-top: 2vh;

  }


`

const Input = styled.input`
  width: 100%;
  height: 5vh;
  border-width: 0.5px;
  border-style: solid;
  border-color: rgb(206, 206, 206);
  margin-bottom: 2vh;
  margin-left: 1vw;
  font-size: 18px;
  padding-left: 0.5vw;
  @media screen and (max-width: 804px) {
    width: 98.5%;
    margin-left: 0vw;

  }
  


`

const Popup = styled.div`
  z-index: 401;
  background-color: white;
  width: 40%;
  height: 50vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  @media screen and (max-width: 1117px) {
    width: 60%;
  }
  @media screen and (max-width: 804px) {
    width: 80%;
  }

`

function Contato() {

  const [email , setEmail] = useState(" ")
  const [titulo , setTitulo] = useState("")
  const [mensagem , setMensagem] = useState("")
  const [isVisible , setVisible] = useState(false)



  function setProperties() {
     const Email = document.getElementById('email').value;
     const Nome = document.getElementById('nome').value;
     const Mensagem = document.getElementById('mensagem').value;
     const Assunto = document.getElementById('assunto').value;


     const titulo = "De:" + Email + "Assunto:" + Assunto
     const msg = "Nome:" + Nome + "Mensagem:" + Mensagem
     
     setEmail(Email)
     setTitulo(titulo)
     setMensagem(msg)


    if(Nome.replace(/\s/g, "") != "" && Assunto.replace(/\s/g, "") != "" && Mensagem.replace(/\s/g, "") != "" && Email.replace(/\s/g, "") != ""){
      setVisible(true)
     }
     return false
     

  }


  return(
    <div className='App' id='contato'>
      <Navbar/>
        <Maps></Maps>
        <Container>
          <Container2>
            <Textopequeno><b>Telefone:</b></Textopequeno>
            <Textopequeno>Lorem ipsum dolor sit amet</Textopequeno>
            <Textopequeno style={{marginTop: "3vh"}}><b>E-mail:</b></Textopequeno>
            <Textopequeno>Lorem ipsum dolor sit amet</Textopequeno>
            <Textopequeno style={{marginTop: "3vh"}}><b>Endereço:</b></Textopequeno>
            <Textopequeno>Lorem ipsum dolor sit amet</Textopequeno>
          </Container2>
          {/* onSubmit={e => e.preventDefault()} */}
          <DivInputs action="https://formsubmit.co/comercial@maiscashe.com" method="POST" >
            <Container style={{width: "100%" , marginTop: "0"}}>
              <Input style={{marginLeft: "0"}} placeholder='Nome' id="nome" name="name" required/>
              <Input placeholder='E-mail' id="email" type='email' name="email" multiple required/>
            </Container>
            <div style={{width: "100%"}}>
              <Input style={{marginLeft: "0" , width: "98%"}} placeholder='Assunto' id="assunto" name="_subject" required/>
              <textarea style={{width: "99%" , height: "15vh" , fontSize: "18px" ,  borderColor: "rgb(206, 206, 206)"}} placeholder="Mensagem" id="mensagem"  name="message" required/> 
            </div>
            <input type="hidden" name="_next" value="https://maiscashe.com/Thanks"/>
            <Button type="submit" onClick={() => setProperties()} value="ENVIAR"></Button>
          </DivInputs>

        </Container>

        {/* <div style={{top: "0" , right: "0", left: "0", bottom:"0", position: "absolute" }} className={`${isVisible ?  "visible" :"invisible"}`}>
          <div style={{ display: "flex" , justifyContent: "center" , alignItems: "center" , width: "100%" , height: "100%", left: "0" , top: "0" , position: "absolute"}}>
              <div style={{width: "100%" , height: "100%" , backgroundColor: "#06060659"  , zIndex: "300", left: "0" , top: "0" , position: "absolute" , cursor: "pointer"}} onClick={() => setVisible(false)}>
              </div>  
              <Popup>
                <img src="/WhatsApp_Image_2024-01-30_at_20.33.20__1_-removebg-preview.png" style={{width: "10%", marginBottom: "2vh"}}></img>
                <p style={{color: "black" , width:"80%"}}>Sucesso! Sua mensagem foi enviada, por gentileza verifique sua caixa de entrada</p>
              </Popup>   
            
            </div>
            
        </div> */}
        

      <Footer/>

    </div>
  )
  
}

export default Contato
