import '../App.css';
import styled from 'styled-components';
import Footer from "../components/Foot.js";
import React from 'react';
import Navbar from "../components/Navbar";
import Consistencia from "../components/Consistencia";
import Estrategia from "../components/Estrategia";

const Img1 = "https://th.bing.com/th/id/R.7d8abb3f9a38b21d8490b6fa66b13750?rik=Tk3Si9p4tEI4xg&pid=ImgRaw&r=0"



const Textopequeno = styled.p`
  text-align: left;
  font-size: 25px;
  z-index: 50;
  width: 25vw;
  color: black;
  position: relative;
  @media screen and (max-width: 1084px) {
    width: 70vw;
    font-size: 22px;
  }

`

const Titulo = styled.h1`
  text-align: left;
  font-weight: 400;
  font-size: 50px;
  z-index: 50;
  position: relative;
  color: black;
  margin-bottom: -0.5vh;
  @media screen and (max-width: 1287px) {
    font-size: 50px;
  }
  @media screen and (max-width: 1084px) {
    width: 70vw;
    font-size: 40px;
  }
  @media screen and (max-width: 616px) {
    font-size: 40px;
  }


`


const Textomedio = styled.h2`
  font-size: 30px;
  z-index: 50;
  position: relative;
  text-align: left;
  font-size: 25px;
  margin-bottom: -2vh;
  color: black;
  @media screen and (max-width: 1483px) {
    font-size: 30px;
  }
  @media screen and (max-width: 613px) {
    width: 50vw;

  }


`

const TextImg = styled.div`
    display: inline-flex;
    margin-top: 8vh;
    @media screen and (max-width: 1084px) {
        display: flex;
        flex-direction: column-reverse;
      }

`
const TextImgI = styled.div`
    display: inline-flex;
    margin-top: 8vh;
    @media screen and (max-width: 1084px) {
        display: flex;
        flex-direction: column;
      }

`

const Img = styled.div`
  background-image: url("${Img1}");
  width: 30vw;
  height: 35vh;
  border-radius: 10px;
  background-position-x: -5vw;
  background-repeat: no-repeat;
  background-size: 45rem;
  @media screen and (max-width: 1353px) {
    background-position-x: -12vw;
  }
  @media screen and (max-width: 1153px) {
    background-position-x: -19vw;
  }
  @media screen and (max-width: 1084px) {
    background-position-x: -15vw;
    width: 90vw;
    height: 55vh;
    background-size: 75rem;
  }
  @media screen and (max-width: 770px) {
    background-size: 58rem;
  }
  @media screen and (max-width: 770px) {
    background-position-x: -30vw;

  }
  @media screen and (max-width: 596px) {
    background-position-x: -50vw;

  }
  @media screen and (max-width: 456px) {
    background-position-x: -58vw;

  }


`



const Divtext = styled.div`
    display: flex;
    flex-direction: column;
    width: 30vw;
    align-items: left;
    justify-content: center;
    margin-left: 5vw;
    @media screen and (max-width: 1084px) {
        margin-left: 0;
      }

`



function Servicos(){
    const img2 = {
        backgroundImage: "url('https://segurabr.com.br/wp-content/uploads/2020/12/Pre%CC%81dios-inteligentes-scaled-e1606835611241.jpg')"
        
    }
    const img3 = {
        backgroundImage: "url('https://www.cedrotech.com/wp-content/uploads/2022/12/tipos-de-seguranca-digital.jpg')"
    }
    const img4 = {
        backgroundImage: "url('https://www.groupsoftware.com.br/blog/wp-content/uploads/2020/07/prestao-de-contas.jpg')"
    }
    const img5 = {
        backgroundImage: "url('https://seucreditodigital.com.br/wp-content/uploads/2022/02/emprestimo.jpg')"
    }

    return(
        <div className='App'>
            <Navbar/>
            <Estrategia temBotao={false}/>

            <TextImgI>
                <Img/>
                <Divtext >
                    <Textomedio>Lorem ipsum</Textomedio>
                    <Titulo>Lorem ipsum</Titulo>
                    <Textopequeno>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</Textopequeno>
                </Divtext>
                
            </TextImgI>

            <TextImg>
                <Divtext style={{marginLeft: "0vw"}}>
                    <Textomedio>Lorem ipsum</Textomedio>
                    <Titulo>Lorem ipsum</Titulo>
                    <Textopequeno>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</Textopequeno>
                </Divtext>
                <Img style={img2}/>
            </TextImg>

            <TextImgI>
                <Img style={img3}/>
                <Divtext >
                    <Textomedio>Lorem ipsum</Textomedio>
                    <Titulo>Lorem ipsum</Titulo>
                    <Textopequeno>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</Textopequeno>
                </Divtext>
                
            </TextImgI>

            <TextImg>
                <Divtext style={{marginLeft: "0vw"}}>
                    <Textomedio>Lorem ipsum</Textomedio>
                    <Titulo>Lorem ipsum</Titulo>
                    <Textopequeno>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</Textopequeno>
                </Divtext>
                <Img style={img4}/>
            </TextImg>

            <TextImgI>
                <Img style={img5}/>
                <Divtext >
                    <Textomedio>Lorem ipsum</Textomedio>
                    <Titulo>Lorem ipsum</Titulo>
                    <Textopequeno>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</Textopequeno>
                </Divtext>
                
            </TextImgI>

            <Consistencia contato={true}/>

            <Footer/>
        </div>
    )
}

export default Servicos;
