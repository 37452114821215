import {Link} from "react-router-dom";
import '../App.css';
import styled from 'styled-components';

const Textopequeno = styled.p`
  text-align: left;
  font-size: 30px;
  z-index: 50;
  position: relative;
  text-align: right;
  @media screen and (max-width: 1084px) {
    font-size: 22px;
  }
  @media screen and (max-width: 786px) {
    text-align: center;
  }
  
  

`

const Foot = styled.footer`
  background-color: #284177;
  width: 100vw;
  margin-top: 5vw;
  @media screen and (max-width: 786px) {
    display: flex;
    flex-direction: column;
  }


`

const Divlinks = styled.div`
  margin-left: 15vw;
  float: left;
  width: 10vw;
  margin-bottom: 10vh;
  @media screen and (max-width: 786px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    float: none;
    width: 95vw;
    margin-left: 0;
    margin-bottom: -1vh;
  }


`

const Divendereco = styled.div`
  float: right;
  margin-right: 15vw;
  margin-top: 12vh;
  @media screen and (max-width: 786px) {
    float: none;
    width: 95vw;
    margin-top: 0vh;
    margin-bottom: 10vh;
  }

`



function Footer(){

  return(
    <Foot>
    <Divlinks>
      <Link to={`/`}>
          <img src="/logo-removebg-preview.png" id="imgFooter"></img>
      </Link>
      <div style={{display: "inline-flex"}}>
        <Link to={`/Sobre`}>
          <Textopequeno >Sobre </Textopequeno>
        </Link>
        
        <Textopequeno style={{color: "white", marginRight: "10px",marginLeft: "10px"}}> -</Textopequeno>
        <Link to={`/Servicos`}>
          <Textopequeno >Serviços</Textopequeno>
        </Link>
        
        <Textopequeno style={{color: "white", marginRight: "10px",marginLeft: "10px"}}>- </Textopequeno>
        <Link to={`/Contato`}>
          <Textopequeno >Contato</Textopequeno>
        </Link>
        
      </div>
      <Textopequeno style={{color: "white", marginTop: "-2vh", textAlign: "left"}}>(Copyright)</Textopequeno>
    </Divlinks>
    <Divendereco>
      <Textopequeno style={{color: "white"}}>(Endereço)</Textopequeno>
      <Textopequeno style={{color: "white", marginTop: "-2vh"}}>(Contatos)</Textopequeno>
    </Divendereco>
  </Foot>

  )

}

export default Footer