import '../App.css';
import styled from 'styled-components';
import Footer from "../components/Foot.js";
import React from 'react';
import Navbar from "../components/Navbar";
import Consistencia from "../components/Consistencia";



const Container = styled.div`
    background-color: #284177;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    @media screen and (max-width: 837px) {
      display: flex;
      flex-direction: column;
      

    }
  

`

const Textopequeno = styled.p`
  text-align: left;
  font-size: 25px;
  z-index: 50;
  position: relative;
  margin-left: 15vw;
  width: 55vw;
  @media screen and (max-width: 1084px) {
    width: 70vw;
    font-size: 22px;
  }
  @media screen and (max-width: 837px) {
    margin-bottom: 15vh;
    margin-left: 0;
  }

`

const Textopequeno2 = styled.p`
  text-align: left;
  font-size: 25px;
  z-index: 50;
  position: relative;
  margin-left: 15vw;
  width: 35vw;
  margin-bottom: 5vh;
  @media screen and (max-width: 1084px) {
    width: 64vw;
    font-size: 22px;
  }

`

const Titulo = styled.h1`
  text-align: left;
  margin-left: 15vw;
  font-weight: 400;
  font-size: 40px;
  z-index: 50;
  position: relative;
  width: 35vw;
  @media screen and (max-width: 1287px) {
    font-size: 50px;
  }
  @media screen and (max-width: 1084px) {
    width: 64vw;
    margin-top: 5vh;
    font-size: 40px;
  }
  @media screen and (max-width: 837px) {
    margin-left: 0;
  }

  @media screen and (max-width: 616px) {
    font-size: 40px;
  }


`

const Number = styled.h1`
    font-size: 60px;
    font-weight: 100;
    margin-top: 15vh;
    margin-bottom: 23vh;
    @media screen and (max-width: 837px) {
      margin-top: 15vh;
      margin-bottom: 0vh;
      margin-left: -60vw;
    }


`

const ImgHeader = styled.div`
  background-image: url("https://th.bing.com/th/id/R.7a8cf145eae2ed2e8b65153227e67ca8?rik=40E9OT1NTrT32A&pid=ImgRaw&r=0");
  width: 13vw;
  height: 45vh;
  margin-left: 2vw;
  background-size: 43rem;
  background-position-x: 29vw;
  @media screen and (max-width: 1472px) {
    background-position-x: 34vw;
  }
  @media screen and (max-width: 1274px) {
    background-position-x: 42vw;
    width: 17vw;
  }
  @media screen and (max-width: 1084px) {
    width: 32vw;
    height: 60vh;
    margin-bottom: 5vh;
    background-size: 56rem;
    background-position-x: 68vw;
  }
  @media screen and (max-width: 836px) {
    background-position-x: 76vw;

  }
  @media screen and (max-width: 762px) {
    width: 37vw;
    height: 50vh;
    background-size: 46rem;

  }
  @media screen and (max-width: 628px) {

    background-position-x: 95vw;
    

  }
  @media screen and (max-width: 508px) {

    background-position-x: 115vw;
    

  }


`
const ImgHeader2 = styled.div`
  background-image: url("https://portaldoemprestimo.com/wp-content/imagens/2018/01/emprestimo-digital-2018.jpg");
  width: 13vw;
  height: 45vh;
  margin-left: 2vw;
  background-size: 46rem;
  background-position-x: 31vw;
  @media screen and (max-width: 1274px) {
    background-position-x: 38vw;
    width: 17vw;
  }
  @media screen and (max-width: 1084px) {
    width: 32vw;
    height: 60vh;
    margin-bottom: 5vh;
    background-size: 60rem;
    background-position-x: 68vw;
  }
  @media screen and (max-width: 762px) {
    width: 37vw;
    height: 50vh;
    background-size: 50rem;
    background-position-x: 78vw;
    

  }
  @media screen and (max-width: 628px) {

    background-position-x: 95vw;
    

  }
  @media screen and (max-width: 508px) {

    background-position-x: 115vw;
    

  }
  


`



const Header = styled.header`
  display: inline-flex;
  margin-top: 18vh;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-bottom: 7vh;
  @media screen and (max-width: 1084px) {
    display: flex;
    flex-direction: column;
  }


`


function Sobre(){
    return(
        <div className='App'>
            <Navbar/>
            <Header>
                <div>
                    <Titulo style={{color:"black", marginLeft: "0"}}>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do</Titulo>
                    <Textopequeno2 style={{color:"black", marginLeft: "0"}}>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam</Textopequeno2>
                </div>

                <div style={{display: "flex"}}>

                  <ImgHeader>
                  </ImgHeader>
                  <ImgHeader2>
                  </ImgHeader2>

                </div>

                
            </Header>

            <Container>
                <Number>01.</Number>
                <div>
                    <Titulo>Lorem ipsum</Titulo>
                    <hr style={{backgroundColor: "white", width: "5vw", marginLeft: "15vw"}}/>
                    <Textopequeno>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</Textopequeno>
                </div>

            </Container>

            <Container style={{backgroundColor: "transparent"}}>
                <Number style={{color:"black"}}>02.</Number>
                <div>
                    <Titulo style={{color:"black"}}>Lorem ipsum</Titulo>
                    <hr style={{ width: "5vw", marginLeft: "15vw"}}/>
                    <Textopequeno style={{color:"black"}}>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</Textopequeno>
                </div>

            </Container>

            <Container>
                <Number>03.</Number>
                <div>
                    <Titulo>Lorem ipsum</Titulo>
                    <hr style={{backgroundColor: "white", width: "5vw", marginLeft: "15vw"}}/>
                    <Textopequeno>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</Textopequeno>
                </div>

            </Container>

            <Consistencia/>

            <Footer/>
        </div>
    )
}

export default Sobre;