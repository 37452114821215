import '../App.css';
import styled from 'styled-components';
import Footer from "../components/Foot";
import React from 'react';
import Navbar from "../components/Navbar";
import Consistencia from "../components/Consistencia";
import Estrategia from "../components/Estrategia";
import {Link} from "react-router-dom";

const Header = styled.header`
  background-color: #284177;
  height: 80vh;
  width: 100vw;
  display: flex;
  align-items: center;
  @media screen and (max-width: 975px) {
    height: 90vh;
  }

`



const Sobre = styled.div`
  margin-top: -13vh;


`


const Textopequeno = styled.p`
  text-align: left;
  font-size: 30px;
  z-index: 50;
  width: 45vw;
  position: relative;
  @media screen and (max-width: 1084px) {
    width: 70vw;
    font-size: 22px;
  }

`

const Titulo = styled.h1`
  text-align: left;
  margin-left: 15vw;
  font-weight: 400;
  font-size: 60px;
  z-index: 50;
  position: relative;
  @media screen and (max-width: 1287px) {
    font-size: 50px;
  }
  @media screen and (max-width: 1084px) {
    width: 70vw;
    font-size: 40px;
  }
  @media screen and (max-width: 616px) {
    font-size: 40px;
  }


`

const TituloH = styled.h1`
  text-align: left;
  margin-left: 15vw;
  font-weight: 400;
  font-size: 60px;
  z-index: 50;
  position: relative;
  width: 45vw;
  @media screen and (max-width: 1287px) {
    font-size: 50px;
  }
  @media screen and (max-width: 1048px) {
    width: 55vw;
  }
  @media screen and (max-width: 782px) {
    width: 65vw;
  }
  @media screen and (max-width: 616px) {
    font-size: 45px;
  }
  @media screen and (max-width: 505px) {
    font-size: 40px;
  }


`


const Boxes = styled.div`
  display: flex;
  justify-content: center;
  @media screen and (max-width: 1084px) {
    flex-direction: column;
    align-items: center;
  }

`

const SobreInfo = styled.div`
  display: inline-flex;
  @media screen and (max-width: 1084px) {
    flex-direction: column;
    align-items: center;
    margin-left: -15vw;
  }

`

const SobreInfodiv = styled.div`
  width: 45vw;
  margin-left: 20vw;
  @media screen and (max-width: 1084px) {
    margin-left: -15vw;
    margin-top: -10vw; 
  }
  @media screen and (max-width: 456px) {
    margin-top: -20vw; 
  }


`

const SobreText = styled.div`
  width: 22vw;
  @media screen and (max-width: 1084px) {
    width: 80vw;
  }


`





function Home() {

  const [isHover1, setHover1] = React.useState(false);
  const [isHover2, setHover2] = React.useState(false);
  const [isHover3, setHover3] = React.useState(false);

 

  function Explore(number){
    if (number == 1){
      setHover1(true)
    } else if(number == 2){
      setHover2(true)
    }else{
      setHover3(true)
    }
    
  }

  function Normal(number){
    if (number == 1){
      setHover1(false)
    } else if(number == 2){
      setHover2(false)
    }else{
      setHover3(false)
    }
  }


  return (
    <div className="App">

      <Navbar/>

      <Header>
        <TituloH>Olá, Somos a MAIS Cash 🔵⚪
        </TituloH>
      </Header>
      <Sobre>
        <Boxes>

          <div className='box' id="emprestimo" onMouseOver={() => Explore(1)}  onMouseLeave={() => Normal(1)}>
            <img src="https://blobportaishml.paranabanco.com.br/portalblogaposentado/2020/10/O-que-e-emprestimo-consignado.jpg" className='imgsSobre'/>
            <p className={`${isHover1 ? "invisible" : "sobreText"}`} id="sobreText1">Empréstimo</p>
            <Link to={`/Sobre`} >
                <button className={`${isHover1 ? "sobreBotao" : "invisible"}`} id="sobreBotao1" >Explore</button>
            </Link>
          </div>
    
          <div className='box' id='prazoTaxa' onMouseOver={() => Explore(2)}  onMouseLeave={() => Normal(2)}>
            <img src="https://www.moloni.pt/_imagens/?macro=imgDetalheZoomHz_2&img=2020/Mar/prazo_de_pagamento_irc_sem_logo_1000x600_k696.png" className='imgsSobre'/>
            <p className={`${isHover2 ? "invisible" : "sobreText"}`} id="sobreText2">Prazos e Taxas</p>
            <Link to={`/Sobre`} >
                <button className={`${isHover2 ? "sobreBotao" : "invisible"}`} id="sobreBotao2" >Explore</button>
            </Link>
          </div>
         
          <div className='box' id='contaDigital' onMouseOver={() => Explore(3)}  onMouseLeave={() => Normal(3)}>
            <img src="https://www.efetividade.blog.br/assets/images/2020/10/quais-as-corretoras-com-melhores-taxas-ou-taxa-zero-para-acoes/conta-digital-vale-a-pena-abrir-uma-20200723180055-jpg.jpg?_cchid=7a27d896da09038288d9822760d5a806" className='imgsSobre'/>
            <p className={`${isHover3 ? "invisible" : "sobreText"}`} id="sobreText3">Conta Digital</p>
            <Link to={`/Sobre`} >
                <button className={`${isHover3 ? "sobreBotao" : "invisible"}`} id="sobreBotao3" >Explore</button>
            </Link>
          </div>
          
        </Boxes>
        <SobreInfo>
          <Titulo style={{color: "black", marginTop: "20vh", marginLeft: "10vw"}}>Sobre</Titulo>
          <SobreInfodiv>
            <Titulo style={{color: "black", marginTop: "20vh", marginLeft: "0vw"}}>Somos uma fintech!</Titulo>
            <Textopequeno style={{color: "black"}}>"Fintech" vem do termo e junção "Financial technology". Viemos para facilitar sua vida financeira, não somos um banco, aaaainda.</Textopequeno>
            <SobreInfo style={{marginLeft: "0vw", flexWrap: "wrap"}}>
              <SobreText>
                <Textopequeno style={{color: "black"}}>Agilidade</Textopequeno>
                <hr/>
              </SobreText>
              <SobreText>
                <Textopequeno style={{color: "black"}}>Segurança</Textopequeno>
                <hr/>
              </SobreText>
              <SobreText>
                <Textopequeno style={{color: "black"}}>Tecnologia</Textopequeno>
                <hr/>
              </SobreText>
              <SobreText>
                <Textopequeno style={{color: "black"}}>Experiência</Textopequeno>
                <hr/>
              </SobreText>
            </SobreInfo>
            <Link to={`/Sobre`} >
                <button type='button' className='botaoPreto'>CONHEÇA</button>
            </Link>
          </SobreInfodiv>
          
        </SobreInfo>
      </Sobre>
      <Consistencia/>
      <Estrategia/>
      <Footer/>

    </div>
  );

  
}



export default Home;
