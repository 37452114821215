import Contato from "./Contato";
import Home from "./Home";
import Servicos from "./Servicos";
import Sobre from "./Sobre";
import Thanks from "./Thanks";
import { BrowserRouter , Routes , Route } from 'react-router-dom'
import { BrowserRouter as Router } from 'react-router-dom';


function App(){



    return (
       <Router>
            <Routes>
                    <Route path='/' element={<Home />} />
                    <Route path="Contato" element={<Contato />}/>
                    <Route path="Servicos" element={<Servicos />}/>
                    <Route path="Sobre" element={<Sobre />}/>
                    <Route path="Thanks" element={<Thanks />}/>
            </Routes>
        </Router> 

            
        );
    
}


export default App;